import { useState } from 'react'
import styled from 'styled-components'
import { sendEmail } from '../services/email-service'
import Button from './Button'
import { LoadingSpinner, VerticalSpacer } from './_common'

export const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    })
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false)
    const isAnyFieldEmpty =
        !formData.name || !formData.email || !formData.message
    const isStatusFieldsEmptyError = status === 'Please fill in all fields.'
    const isSuccessful = status === 'Email sent successfully!'

    const handleChange = (e: { target: { name: any; value: any } }) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault()
        setHasAttemptedSubmit(true)
        const { name, email, message } = formData
        setStatus('')
        if (!name || !email || !message) {
            setStatus('Please fill in all fields.')
            return
        }

        setLoading(true)

        sendEmail({ name, email, message })
            .then(() => {
                setStatus('Email sent successfully!')
                setFormData({ name: '', email: '', message: '' })
            })
            .catch((error) => {
                setStatus('Failed to send email. Please try again later.')
            })
            .finally(() => {
                setLoading(false)
                setHasAttemptedSubmit(false)
            })
    }

    return (
        <ContactFormContainer>
            <form className="contact-form" onSubmit={handleSubmit}>
                <FormRow>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="name*"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </FormRow>
                <FormRow>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="email*"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </FormRow>

                <FormRow className="form-message-row">
                    <textarea
                        id="message"
                        name="message"
                        placeholder="message*"
                        value={formData.message}
                        onChange={handleChange}
                        style={{ resize: 'none' }}
                    ></textarea>
                </FormRow>
                <VerticalSpacer size="1.5rem" />
                <FormRow className="button-row">
                    <Button
                        backgroundColor="var(--gradient-green-to-blue)"
                        disabled={isAnyFieldEmpty}
                        text={
                            loading ? (
                                <LoadingSpinner />
                            ) : isStatusFieldsEmptyError &&
                              hasAttemptedSubmit ? (
                                status
                            ) : (
                                'Send Message'
                            )
                        }
                        onClick={(e) => handleSubmit(e)}
                    />
                </FormRow>
                {isSuccessful && (
                    <div
                        style={{
                            color: 'var(--primary-color)',
                            textAlign: 'center',
                            width: '100%',
                            marginTop: '2rem',
                            fontSize: 'var(--font-base)',
                        }}
                    >
                        {status}
                        <br />
                        <small
                            style={{
                                color: 'var(--gray)',
                            }}
                        >
                            I'll get back to you as soon as possible
                        </small>
                    </div>
                )}
            </form>
        </ContactFormContainer>
    )
}

const ContactFormContainer = styled.div`
    width: 100%;
    transition: 0.3s ease all;
`

const FormRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 5px;

    input,
    textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid var(--light-gray);
        border-radius: 2px;
        background-color: var(--white);
        font-size: 16px;
        color: var(--primary-text-color);
        transition:
            border-color 0.2s,
            box-shadow 0.2s;
        font-family: var(--primary-font);
    }

    input:focus,
    textarea:focus {
        border-color: var(--link-color);
        box-shadow: 0 0 0 1px var(--link-color);
        outline: none;
    }

    textarea {
        width: 100%;
        height: 100px;
    }

    &.button-row {
        justify-content: flex-end;
        width: 100%;

        button {
            width: 100%;
        }
    }
`

const mediaQuery = `@media (max-width: 1083px) {
    ${ContactFormContainer} {
        width: 90%;
    }

    ${FormRow} {
        width: 100%;
    }
}`

export default mediaQuery
