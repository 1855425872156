import styled from 'styled-components'

export const LoadingSpinner = styled.div<{
    size?: string
    color?: string
}>`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: ${(props) => props.color || 'var(--wite)'};
    border-radius: 50%;
    width: ${(props) => props.size || '20px'};
    height: ${(props) => props.size || '20px'};
    animation: spin 1s linear infinite;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const PageContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--light-gray);
    margin: 0;
    width: 100%;
    display: flex;

    align-items: center;
    align-self: center;
    height: 100%;
    min-height: calc(100vh - 80px);

    @media (max-width: 1169px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`

export const StyledLink = styled.span`
     textDecoration: none;
     cursor: pointer;
     transition: color 0.2s;
     color: var(--link-color);

    &:hover {
        color: var(--secondary-color)
    `

export const StyledExternalLink = styled.a`
     textDecoration: none;
     cursor: pointer;
     transition: color 0.2s;
     color: var(--link-color);

    &:hover {
        color: var(--secondary-color) !important;
    `

export const Italics = styled.span`
    font-style: italic;
`

export const NavContainer = styled.div`
    position: relative;
    top: 0;
    width: 100%;
`

export const VerticalSpacer = styled.div<{ size?: string }>`
    height: ${(props) => props.size || '1rem'};
`

export const HorizontalSpacer = styled.span`
    margin-right: 1rem;
`

export const FlexRow = styled.div<{
    justifyContent?: React.CSSProperties['justifyContent']
}>`
    display: flex;
    justify-content: ${(props) => props.justifyContent || 'center'};
    align-items: center;
`

export const Grid2x2 = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 0rem;
`

export const FlexColumn = styled.div<{
    alignItems?: React.CSSProperties['alignItems']
}>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.alignItems || 'center'};
`
export const GradientTitleText = styled.h1<{ color1: string; color2: string }>`
    font-size: var(--font-lg);
    line-height: var(--line-height-lg);
    margin: 0;
    background: linear-gradient(
        45deg,
        ${(props) => props.color1},
        ${(props) => props.color2}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

export const TitleText = styled.h1<{ color?: string }>`
    font-size: var(--font-lg);
    line-height: var(--line-height-lg);
    margin: 0;
    color: ${(props) => props.color || 'var(--primary-text-color)'};
`

export const SubtitleText = styled.h4<{ color?: string }>`
    font-size: var(--font-md-base);
    line-height: var(--line-height-md-base);
    margin: 0;
    color: ${(props) => props.color || 'var(--primary-text-color)'};

    @media (max-width: 400px) {
        font-size: var(--font-sm);
        line-height: var(--line-height-sm);
    }
`

export const BaseText = styled.p<{ color?: string }>`
    font-size: var(--font-base);
    line-height: var(--line-height-base);
    color: ${(props) => props.color || 'var(--primary-text-color)'};

    @media (max-width: 768px) {
        font-size: var(--font-base-sm);
        line-height: var(--line-height-base-sm);
    }

    @media (max-width: 600px) {
        font-size: var(--font-sm);
        line-height: var(--line-height-sm);
    }
`

export const SmallText = styled.p<{ color?: string }>`
    font-size: var(--font-sm);
    line-height: var(--line-height-sm);
    color: ${(props) => props.color || 'var(--primary-text-color)'};
`
