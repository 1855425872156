import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openUrlInNewTab, PUBLIC_URL } from '../_const/web'
import styled from 'styled-components'
import { faFile } from '@fortawesome/free-regular-svg-icons'

const Button = styled.button`
    font-size: 1rem;
    color: var(--primary-text-color);
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.2s;
    padding: 0;
    color: var(--primary-color);
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: none;
    &:hover {
        color: var(--link-color);
    }
`

const ChevronIcon = styled(FontAwesomeIcon)`
    font-size: 1.25rem;
    margin-right: 0.25rem;
`

// Path to the PDF file in the public folder
const pdfUrl = PUBLIC_URL + '/JakeCarr_Resume.pdf'

const openPdfNewTab = () => openUrlInNewTab(pdfUrl)

const PdfViewer = () => (
    <Button onClick={openPdfNewTab}>
        <ChevronIcon icon={faFile} /> Download PDF
    </Button>
)
export default PdfViewer
