import styled, { css } from 'styled-components'
import { FlexRow, TitleText, VerticalSpacer } from './_common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
`

export const Description = styled.p<{ color?: string; fontSize?: string }>`
    font-size: ${({ fontSize }) => fontSize || '1.125rem'};
    color: ${({ color }) => color || '#333'};
    line-height: 1.6;
`

const DrawerContent = styled.div<{
    currentOpenDrawer: boolean
    bgColor?: string
    overFlowHidden?: boolean
}>`
    max-height: ${({ currentOpenDrawer }) =>
        currentOpenDrawer ? '500px' : '0'}; /* Adjust max-height as needed */
    overflow: hidden;
    transition:
        max-height 0.4s ease-in-out,
        opacity 0.4s ease-in-out,
        transform 0.4s ease-in-out,
        margin-bottom 0.4s ease-in-out;
    opacity: ${({ currentOpenDrawer }) => (currentOpenDrawer ? '1' : '0')};
    transform: ${({ currentOpenDrawer }) =>
        currentOpenDrawer ? 'translateY(0)' : 'translateY(-10px)'};
    background: ${({ bgColor }) => bgColor || 'var(--secondary-color-light)'};
    border-radius: 4px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: ${({ currentOpenDrawer }) =>
        currentOpenDrawer ? '0.5rem' : '0'};
    padding-bottom: ${({ currentOpenDrawer }) =>
        currentOpenDrawer ? '0.5rem' : '0'};
    margin-top: 5px;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    align-self: flex-start;
    justify-self: flex-start;
`

const DrawerToggleButton = styled.button<{ isOpen: boolean }>`
    background: none;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 1.1rem;
    font-weight: bold;

    &:focus {
        outline: none;
    }

    ${({ isOpen }) =>
        isOpen &&
        css`
            .chevron-icon {
                transform: rotate(90deg);
            }
        `}
`

const ChevronIcon = styled(FontAwesomeIcon)<{ isOpen: boolean }>`
    font-size: 1rem;
    transition: transform 0.4s ease;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0)')};
`

export enum Drawers {
    None,
    WhatIsFullStack,
    ViewWorkHistory,
    ViewProjects,
}

const DrawerComponent = (props: {
    onClickContact: () => void
    currentOpenDrawer: Drawers
    openDrawer: (d: Drawers) => void
}) => {
    const { openDrawer, currentOpenDrawer } = props

    return (
        <Container>
            <TitleText>Software development services</TitleText>
            <Description>
                I&apos;m a full-stack developer specializing in TypeScript,
                Node, React, and AWS. I&apos;ve built apps, video software,
                payment systems, and dashboards for visualizing and managing all
                kinds of data. I hold several professional certifications from
                Amazon Web Services that demonstrate my knowledge and ability to
                deliver fast, secure, and scalable systems on reliable cloud
                infrastructure.
                <VerticalSpacer />
                Whether you need a small business website, a game, or a custom
                application, I can help you bring your ideas to life with
                consulting, development, and ongoing support services.
            </Description>
            <div id="drawers-buttons">
                <FlexRow
                    style={{
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: '5px',
                    }}
                >
                    <DrawerToggleButton
                        isOpen={currentOpenDrawer === Drawers.WhatIsFullStack}
                        onClick={() => openDrawer(Drawers.WhatIsFullStack)}
                    >
                        <ChevronIcon
                            className="chevron-icon"
                            icon={faChevronRight}
                            isOpen={
                                currentOpenDrawer === Drawers.WhatIsFullStack
                            }
                        />
                        <span style={{ marginLeft: '8px' }}>
                            What is a full-stack developer?
                        </span>
                    </DrawerToggleButton>
                </FlexRow>
                <DrawerContent
                    currentOpenDrawer={
                        currentOpenDrawer === Drawers.WhatIsFullStack
                    }
                >
                    <Description fontSize="1rem">
                        💡 A full-stack developer can take an idea and help
                        shape an idea into a finished product, handling both the
                        front end and back end.
                        <br />
                        ⚙️ Front-end developers build the parts of applications
                        that users see and interact with, while back-end
                        developers take care of everything behind the scenes,
                        such as the server, database, and business logic.
                        <br />
                        🤝 I can guarantee delivery of a complete, polished, and
                        scalable product.
                    </Description>
                </DrawerContent>
            </div>
        </Container>
    )
}

export default DrawerComponent
