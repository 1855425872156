import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { GradientTitleText, SmallText, VerticalSpacer } from './_common'

const Overlay = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
    transition:
        opacity 0.3s ease-in-out,
        visibility 0.3s ease-in-out;
`

const ModalContainer = styled.div<{ isOpen: boolean }>`
    background: var(--light-gray);
    padding: 40px 30px;
    border-radius: 12px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transform: ${(props) =>
        props.isOpen ? 'translateY(0)' : 'translateY(-20px)'};
    transition: transform 0.3s ease-in-out;



 
    }
`

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 30px;
    cursor: pointer;
    color: var(--black);
    transition-duration: 0.3s;

    &:hover {
        color: var(--danger-color);
    }
`

const Modal = ({
    children,
    isOpen,
    onClose,
}: {
    children: React.ReactNode
    isOpen: boolean
    onClose: () => void
}) => {
    const ref = useRef<HTMLDivElement>(null)
    // useOnClickOutside(ref, onClose)

    useEffect(() => {
        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose()
            }
        }
        document.addEventListener('keydown', handleEscape)
        return () => {
            document.removeEventListener('keydown', handleEscape)
        }
    }, [onClose])

    return (
        <Overlay isOpen={isOpen} onClick={onClose}>
            <ModalContainer
                isOpen={isOpen}
                ref={ref}
                onClick={(e) => e.stopPropagation()}
            >
                <CloseButton onClick={onClose}>&times;</CloseButton>
                <GradientTitleText
                    color1={'var(--secondary-color)'}
                    color2={'var(--link-color)'}
                >
                    Contact
                </GradientTitleText>
                <SmallText>
                    If you have an idea for a project, please get in touch.
                    <br />
                    <small>
                        I strive to respond to all inquiries within 24 hours.
                    </small>
                </SmallText>
                <VerticalSpacer />
                {children}
            </ModalContainer>
        </Overlay>
    )
}

export default Modal
