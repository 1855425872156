import './App.css'
import { Route, Routes } from 'react-router-dom'
import { MainContainer } from './components/_common'
import { useState } from 'react'
import Modal from './components/Modal'
import { ContactForm } from './components/ContactForm'
import SinglePageApp from './pages/Singleton'

function App() {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => setIsModalOpen(true)
    const closeModal = () => setIsModalOpen(false)
    return (
        <div className="App">
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <ContactForm />
            </Modal>
            <Routes>
                <Route
                    path="/"
                    element={
                        <MainContainer>
                            <SinglePageApp onClickContact={openModal} />
                        </MainContainer>
                    }
                />

                <Route
                    path="*"
                    element={
                        <MainContainer>
                            <SinglePageApp onClickContact={openModal} />
                        </MainContainer>
                    }
                />
            </Routes>
        </div>
    )
}

export default App
