import emailjs from '@emailjs/browser'
import INFO from '../_const/info'

const PUBLIC_KEY = 'nr13x60CQ_iYAPrBo'
const SERVICE_ID = 'jake_service'
const TEMPLATE_ID = 'template_5br9w5v'

emailjs.init({ publicKey: PUBLIC_KEY })

export function sendEmail(props: {
    name: string
    email: string
    message: string
}) {
    const { name, email, message } = props
    let fullMsg = message

    if (!!name?.length) {
        fullMsg = `${fullMsg}\n\n *** 📝 Provided name: ${name} ***`
    }

    if (!!email?.length) {
        fullMsg = `${fullMsg}\n\n *** 📧 Provided email address: ${email} ***`
    }

    return emailjs.send(SERVICE_ID, TEMPLATE_ID, {
        to_email: INFO.main.email,
        from_name: name,
        from_email: email,
        message: fullMsg,
    })
}
