import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'
import { faGithubSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import INFO from '../_const/info'
import { StyledLink } from './_common'

const Container = styled.span`
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;

    .social {
        cursor: pointer;
        color: var(--black);
        transition: color 0.2s;

        &:hover {
            color: var(--secondary-color);
            .social-icon {
                color: var(--secondary-color);
            }
            .social-text {
                color: var(--secondary-color);
            }
        }
    }

    .social a,
    .social span#email {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 4px;
        transition: color 0.2s;
        text-decoration: none;
        color: #333;
    }

    .social-icon {
        transition: color 0.2s;
        font-size: 32px;
        width: 32px;

        color: var(--primary-color);
    }

    .social-text {
        transition: color 0.2s;
        font-size: 1.125mrem;
        color: #333;
    }
`

const Socials = (props: {
    emailOnly?: boolean
    noEmail?: boolean
    onClickContact?: () => void
}) => {
    const { emailOnly, noEmail, onClickContact } = props
    return (
        <Container>
            {!noEmail && (
                <span className="social">
                    {!!onClickContact && (
                        <StyledLink onClick={onClickContact} id="email">
                            <span className="social-icon">
                                <FontAwesomeIcon icon={faEnvelopeSquare} />
                            </span>
                            {/* <span className="social-text">Email</span> */}
                        </StyledLink>
                    )}
                </span>
            )}
            {!emailOnly && (
                <span className="social">
                    <a
                        href={INFO.socials.linkedin}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span className="social-icon">
                            <FontAwesomeIcon
                                icon={faLinkedin}
                                className="social-icon"
                            />
                        </span>
                        {/* <span className="social-text">LinkedIn</span> */}
                    </a>
                </span>
            )}

            {!emailOnly && (
                <>
                    <span className="social">
                        <a
                            href={INFO.socials.github}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className="social-icon">
                                <FontAwesomeIcon
                                    icon={faGithubSquare}
                                    className="social-icon"
                                />
                            </span>
                            {/* <span className="social-text">GitHub</span> */}
                        </a>
                    </span>
                </>
            )}
        </Container>
    )
}

export default Socials
