interface Information {
    main: {
        title: string
        name: string
        email: string
        logo: string
    }
    socials: {
        github: string
        linkedin: string
    }
    projects: Array<{
        title: string
        description: string
        logo: string
        linkText: string
        link: string
    }>
}

const INFO: Information = {
    main: {
        title: 'Jake Carr',
        name: 'Jake Carr',
        email: 'jake.ralph.carr@gmail.com',
        logo: '../logo.png',
    },

    socials: {
        github: 'https://github.com/jake-carr',
        linkedin: 'https://linkedin.com/in/jakecarr2',
    },
    projects: [
        {
            title: 'Bauble',
            description:
                'I currently work as the lead designer of a multiplayer strategy game.',
            logo: 'ext-logos/ue.svg',
            linkText: 'Learn more about the game',
            link: 'https://goodanimalgames.com/',
        },
        // {
        // 	title: "Workstable",
        // 	description:
        // 		"Built the user interface for a job-seeking subscription service that uses a machine learning algorithm to match user resumes with the perfect opportunities.",
        // 	logo: "https://static-00.iconduck.com/assets.00/horse-emoji-2048x1764-230e77hh.png",
        // 	linkText: "Try it out",
        // 	link: "https://github.com",
        // },
        // {
        //     title: 'Jyoti Prakas, Tabla Maestro',
        //     description:
        //         'Designed and built a website for a globally renowed tabla musician and teacher based in Los Angeles',
        //     logo: 'ext-logos/jyoti.png',
        //     linkText: 'Visit',
        //     link: 'http://jyoti-prakas-bucket.s3-website-us-east-1.amazonaws.com/',
        // },
        {
            title: 'Kat Kehoe, Realtor',
            description:
                'Implemented a website with a custom property feed for a Richmond, VA based realtor.',
            logo: 'ext-logos/kat-kehoe-logo.webp',
            linkText: 'Tour properties',
            link: 'https://www.katkehoe.com/properties',
        },

        // {
        //     title: 'Tabla Zone',
        //     description:
        //         'Free, lightweight metronome app for students of Indian music to practice rhythmic cycles using an and intuitive interface and open-source sounds.',
        //     logo: 'ext-logos/jyoti.png',
        //     linkText: 'Start grooving',
        //     link: 'https://github.com/jake-carr/tabla-zone',
        // },
        // {
        //     title: 'TypeScript Discord Bot Template',
        //     description:
        //         'Created a quick-start project with instructions for developers to easily start building their own Discord bots.',
        //     logo: 'ext-logos/discord.png',
        //     linkText: 'Clone or fork the template',
        //     link: 'https://github.com/jake-carr/discord-bot-ts-template',
        // },
    ],
}

export default INFO
