import { Helmet } from 'react-helmet'
import { pageTitle } from '../_const/pages'
import {
    FlexColumn,
    PageContentContainer,
    TitleText,
    VerticalSpacer,
    StyledLink,
    SubtitleText,
} from '../components/_common'
import Socials from '../components/Socials'
import INFO from '../_const/info'
import Footer from '../components/Footer'
import DrawerComponent, { Description, Drawers } from '../components/Drawer'
import { openUrlInNewTab } from '../_const/web'
import { useState } from 'react'
import styled from 'styled-components'
import AllProjects from '../components/ProjectsAll'
import VerticalTimelineComponent from '../components/VerticalTimeline'
import PdfViewer from '../components/PdfViewer'
import { SEO } from '../seo/seo'
import useBreakpoints, { EScreenBreakpoint } from '../hooks/useBreakpoints'

export function smoothScrollToElement(id?: string, bp?: EScreenBreakpoint) {
    if (id) {
        const element = document.getElementById(id)
        if (element) {
            // Scroll into view, accounting for the sticky nav bar
            const basePad = 20
            const navHeight = bp === EScreenBreakpoint.Desktop ? 116 : 161
            const offset = basePad + navHeight
            window.scrollTo({
                top: element.offsetTop - offset,
                behavior: 'smooth',
            })
            return
        }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

const Nav = styled.nav`
    width: 100%;
    background-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`

const NavLinkWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
`

const NavLink = styled.button`
    @keyframes underline-grow {
        from {
            width: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        to {
            width: 100%;
            left: 0;
            transform: translateX(0);
        }
    }
    background-color: var(--primary-color);
    color: var(--white);
    padding: 0.5rem 0rem;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 1rem;
    position: relative;
    transition: color 0.2s;

    @media (max-width: 1000px) {
        margin-top: 0rem;
    }
    @media (max-width: 600px) {
        font-size: 1rem;
        margin: 0.5rem;
        margin-top: 0rem;
    }
    @media (max-width: 400px) {
        font-size: 0.8rem;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 0;
        background-color: var(--white);
        transition: width 0.3s ease-in-out;
    }

    &:hover::after {
        width: 100%;
    }

    &:not(:hover)::after {
        width: 0;
    }
`

export function TitleCard() {
    return (
        <div
            style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    margin: '1rem',
                    width: 80,
                }}
            >
                <img
                    src="about.webp"
                    alt="about"
                    className="homepage-image"
                    width="100%"
                    height="auto"
                />
            </div>
            <FlexColumn alignItems="flex-start">
                <TitleText>Jake Carr</TitleText>
                <SubtitleText color="var(--white)">
                    Web & game developer
                </SubtitleText>
            </FlexColumn>
        </div>
    )
}

export default function SinglePageApp(props: { onClickContact: () => void }) {
    const [isOpen, setIsOpen] = useState(Drawers.None)

    const openDrawer = (drawer: Drawers) => {
        if (isOpen === drawer) {
            setIsOpen(Drawers.None)
        } else {
            setIsOpen(drawer)
        }
    }

    const bp = useBreakpoints()

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={SEO.description} />
                <meta name="keywords" content={SEO.keywords.join(', ')} />
            </Helmet>

            <Nav>
                <TitleCard />
                <NavLinkWrapper>
                    <NavLink
                        onClick={() => smoothScrollToElement(undefined, bp)}
                    >
                        Services
                    </NavLink>
                    <NavLink
                        onClick={() => smoothScrollToElement('projects', bp)}
                    >
                        Projects
                    </NavLink>
                    <NavLink
                        onClick={() => smoothScrollToElement('resume', bp)}
                    >
                        Resume
                    </NavLink>
                    <NavLink onClick={props.onClickContact}>Contact</NavLink>
                </NavLinkWrapper>
            </Nav>

            <PageContentContainer>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '75%',
                        paddingTop: '2rem',
                        paddingBottom: '2rem',
                    }}
                >
                    <VerticalSpacer />
                    <DrawerComponent
                        onClickContact={props.onClickContact}
                        currentOpenDrawer={isOpen}
                        openDrawer={openDrawer}
                    />
                    <VerticalSpacer />
                    <FlexColumn
                        style={{
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                            width: '100%',
                        }}
                    >
                        <TitleText color="var(--primary-text-color)">
                            Video games
                        </TitleText>

                        <Description>
                            {`I've been designing and programming games for almost
                            two decades, starting as a hobbyist with StageCast
                            Creator and GameMaker Studio in the 2000s. Since
                            2023 my professional focus has been on mastering
                            Unreal Engine and C++ for multiplayer game
                            development.`}
                        </Description>
                    </FlexColumn>

                    <FlexColumn
                        id="projects"
                        style={{
                            width: '100%',
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}
                    >
                        <TitleText>Projects</TitleText>
                        <Description>
                            Here are some of the things I've been working on
                            recently. Please feel free to{' '}
                            <StyledLink onClick={props.onClickContact}>
                                reach out
                            </StyledLink>{' '}
                            with any comments or inquiries. You can find code
                            samples and other projects on my{' '}
                            <StyledLink
                                onClick={() =>
                                    openUrlInNewTab(INFO.socials.github)
                                }
                            >
                                GitHub
                            </StyledLink>
                            .
                        </Description>
                    </FlexColumn>
                    <AllProjects />
                    <VerticalSpacer size="1rem" />

                    <FlexColumn
                        id="resume"
                        style={{
                            width: '100%',
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}
                    >
                        <TitleText>Resume</TitleText>
                        <VerticalSpacer />
                        <PdfViewer />
                        <VerticalSpacer />
                        <VerticalTimelineComponent />
                    </FlexColumn>
                    <FlexColumn
                        style={{
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                            width: '100%',
                        }}
                    >
                        <TitleText color="var(--primary-text-color)">
                            Music
                        </TitleText>

                        <Description>
                            When I'm not at the computer, I like to play the
                            sitar, guitar, and drums.
                            <br />
                            I've studied and performed Indian classical music
                            live in Los Angeles.
                        </Description>
                    </FlexColumn>

                    <FlexColumn
                        style={{
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                            width: '100%',
                        }}
                    >
                        <TitleText color="var(--primary-text-color)">
                            Connect
                        </TitleText>
                        <VerticalSpacer />
                        <Socials onClickContact={props.onClickContact} />
                    </FlexColumn>
                </div>

                <Footer />
            </PageContentContainer>
        </>
    )
}
