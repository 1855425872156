import React from 'react'
import styled from 'styled-components'
import Project from './Project'
import INFO from '../_const/info'

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
    }
`

const ProjectWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
`

const AllProjects: React.FC = () => {
    return (
        <Container>
            {INFO.projects.map(
                (
                    project: {
                        logo: string
                        title: string
                        description: string
                        linkText: string
                        link: string
                    },
                    index: React.Key | null | undefined
                ) => (
                    <ProjectWrapper key={index}>
                        <Project
                            logo={project.logo}
                            title={project.title}
                            description={project.description}
                            linkText={project.linkText}
                            link={project.link}
                        />
                    </ProjectWrapper>
                )
            )}
        </Container>
    )
}

export default AllProjects
