import {
    faCertificate,
    faCheckCircle,
    faGraduationCap,
    faSchool,
    faWrench,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import '../_const/vertical-timeline-styles-override.css'
import { Italics, StyledExternalLink } from './_common'
import useBreakpoints, { EScreenBreakpoint } from '../hooks/useBreakpoints'

const WorkIcon = () => {
    return <FontAwesomeIcon icon={faWrench}></FontAwesomeIcon>
}

const InstituteIcon = () => {
    return <FontAwesomeIcon icon={faSchool}></FontAwesomeIcon>
}

const SchoolIcon = () => {
    return <FontAwesomeIcon icon={faGraduationCap}></FontAwesomeIcon>
}

const CertificateIcon = () => {
    return <FontAwesomeIcon icon={faCertificate}></FontAwesomeIcon>
}

export default function VerticalTimelineComponent() {
    const breakpoint = useBreakpoints()

    const certificateIconStyle: React.CSSProperties = {
        background: 'var(--link-color)',
        color: 'var(--white)',
        width: '35px',
        height: '35px',
        paddingLeft: breakpoint === EScreenBreakpoint.Desktop ? '1px' : '0px',
        marginLeft:
            breakpoint === EScreenBreakpoint.Desktop ? '-18px' : '2.5px',
        marginTop: breakpoint === EScreenBreakpoint.Desktop ? '12px' : '7.5px',
    }

    return (
        <VerticalTimeline lineColor="var(--black)">
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                    background: 'var(--secondary-color)',
                    color: 'var(--black)',
                    marginTop: '1rem',
                }}
                contentArrowStyle={{
                    borderRight: '7px solid  var(--secondary-color)',
                }}
                date="Jan 2024 - present"
                iconStyle={{
                    background: 'var(--secondary-color)',
                    color: 'var(--black)',
                }}
                icon={<WorkIcon />}
            >
                <h3 className="vertical-timeline-element-title">
                    Game Designer and Engineer
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                    Good Animal LLC
                </h4>
                <p>
                    Managing a small team of experienced engineers and artists
                    to create a whimsical, seamless, and friendly game
                    experience for all ages and skill levels.
                </p>
                <p>
                    <small style={{ color: 'var(--dark-gray)' }}>
                        Unreal Engine, C++, Multiplayer Networking, Creative
                        Direction, Project Management, Team Leading
                    </small>
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="May 2021 - Jan 2024"
                iconStyle={{
                    background: 'var(--secondary-color)',
                    color: 'var(--white)',
                }}
                icon={<WorkIcon />}
            >
                <h3 className="vertical-timeline-element-title">
                    Full-Stack Engineer
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                    FreshCut Interactive
                </h4>
                <p>API, video system, and web app design and implementation.</p>
                <p>
                    <small style={{ color: 'var(--gray)' }}>
                        TypeScript, Golang, ffmpeg, Docker, Postgres, MongoDB,
                        Amazon Web Services, Google Cloud Platform, Terraform,
                        Firebase, Next.js, Figma
                    </small>
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--certificate"
                date="2023"
                iconStyle={certificateIconStyle}
                icon={<CertificateIcon />}
            >
                <h3 className="vertical-timeline-element-title">
                    <StyledExternalLink
                        style={{
                            textDecoration: 'none',
                        }}
                        href="https://aws.amazon.com/certification/certified-devops-engineer-professional"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <span style={{ marginLeft: '5px' }}>
                            AWS DevOps Engineer - Professional
                        </span>
                    </StyledExternalLink>
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                    Amazon Web Services
                </h4>
                <p>
                    Showcases technical expertise in provisioning, operating,
                    and managing different kinds of distributed application
                    systems on the AWS platform.
                    <br />
                    <br />
                    <Italics>
                        <small style={{ color: 'var(--gray)' }}>
                            "These qualified professionals can ensure speedy
                            delivery of secure, compliant systems that are
                            highly available and scalable."{' '}
                        </small>
                    </Italics>
                </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--certificate"
                date="2022"
                iconStyle={certificateIconStyle}
                icon={<CertificateIcon />}
            >
                <h3 className="vertical-timeline-element-title">
                    <StyledExternalLink
                        style={{
                            textDecoration: 'none',
                        }}
                        href="https://aws.amazon.com/certification/certified-solutions-architect-professional"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <span style={{ marginLeft: '5px' }}>
                            AWS Solutions Architect - Professional
                        </span>
                    </StyledExternalLink>
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                    Amazon Web Services
                </h4>
                <p>
                    Demonstrates advanced knowledge and skills in providing
                    complex solutions to complex problems, optimizing security,
                    cost, and performance, and automating manual processes.
                    <br />
                    <br />
                    <Italics>
                        <small style={{ color: 'var(--gray)' }}>
                            "This certification is a means for clients and
                            organizations to identify and develop talent with
                            the critical skills for implementing cloud
                            initiatives."
                        </small>
                    </Italics>
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--certificate"
                date="2021"
                iconStyle={certificateIconStyle}
                icon={<CertificateIcon />}
            >
                <h3 className="vertical-timeline-element-title">
                    <StyledExternalLink
                        style={{
                            textDecoration: 'none',
                        }}
                        href="https://aws.amazon.com/certification/certified-developer-associate"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <span style={{ marginLeft: '5px' }}>
                            AWS Developer - Associate
                        </span>
                    </StyledExternalLink>
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                    Amazon Web Services
                </h4>
                <p>
                    Demonstrates knowledge in building, optimizing, packaging,
                    and deploying applications, using CI/CD workflows, and
                    identifying and resolving application issues.
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="Dec 2020 - Apr 2021"
                iconStyle={{
                    background: 'var(--secondary-color)',
                    color: 'var(--white)',
                }}
                icon={<InstituteIcon />}
            >
                <h3 className="vertical-timeline-element-title">
                    Software Engineering Immersive
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                    Hack Reactor
                </h4>
                <p>Full-stack programming bootcamp in Los Angeles, CA.</p>
                <p>
                    <small style={{ color: 'var(--gray)' }}>
                        JavaScript, React, Node, SQL, Git, HTML, CSS
                    </small>
                </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2018-2020"
                iconStyle={{
                    background: 'var(--secondary-color)',
                    color: 'var(--white)',
                }}
                icon={<SchoolIcon />}
            >
                <h3 className="vertical-timeline-element-title">
                    Associate of Science
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                    Santa Monica College
                </h4>
                <p>Web Programming, Cloud Computing</p>
            </VerticalTimelineElement>
        </VerticalTimeline>
    )
}
