import styled from 'styled-components'

const FooterContainer = styled.div`
    justify-self: flex-end;
    align-self: flex-end;
`
const FooterDisclaimer = styled.div`
    padding-right: 2rem;
    padding-bottom: 2rem;
    text-align: right;
    background-color: var(--light-gray);
    color: var(--black);
`

export default function Footer() {
    return (
        <FooterContainer>
            <FooterDisclaimer>© Jake Carr 2024</FooterDisclaimer>
        </FooterContainer>
    )
}
