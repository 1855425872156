import { useEffect, useState } from 'react'

// --bp-mini: 600px;
// --bp-mobile: 768px;
// --bp-table: 1169px;
// --bp-desktop: 1246px;

export enum EScreenBreakpoint {
    Mini = 'mini',
    Mobile = 'mobile',
    Table = 'table',
    Desktop = 'desktop',
}

const useBreakpoints = () => {
    const [breakpoint, setBreakpoint] = useState<EScreenBreakpoint>(
        EScreenBreakpoint.Desktop
    )

    useEffect(() => {
        const handleResize = () => {
            if (
                window.innerWidth <
                parseInt(
                    getComputedStyle(document.documentElement).getPropertyValue(
                        '--bp-mini'
                    )
                )
            ) {
                setBreakpoint(EScreenBreakpoint.Mini)
            } else if (
                window.innerWidth <
                parseInt(
                    getComputedStyle(document.documentElement).getPropertyValue(
                        '--bp-mobile'
                    )
                )
            ) {
                setBreakpoint(EScreenBreakpoint.Mobile)
            } else if (
                window.innerWidth <
                parseInt(
                    getComputedStyle(document.documentElement).getPropertyValue(
                        '--bp-table'
                    )
                )
            ) {
                setBreakpoint(EScreenBreakpoint.Table)
            } else {
                setBreakpoint(EScreenBreakpoint.Desktop)
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return breakpoint
}

export default useBreakpoints
