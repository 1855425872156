import styled from 'styled-components'

const StyledButton = styled.button<{
    color?: string
    backgroundColor?: string
}>`
    position: relative;
    width: auto;
    height: 40px;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    border-radius: 4px;
    border: none;
    box-shadow: var(--button-box-shadow);
    color: ${({ color }) => color || 'var(--white)'};
    background: ${({ backgroundColor }) =>
        backgroundColor || 'var(--primary-color)'};
    font-size: var(--font-base);
    line-height: var(--line-height-base);

    @media (max-width: 600px) {
        font-size: var(--font-base-sm);
    }

    &:not([disabled]) {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
    }
    transition-duration: 0.2s;

    // Hovering, but only when not disabled
    &:hover:not([disabled]) {
        background-color: var(--secondary-color);
    }
`

const StyledGradientButton = styled.button<{
    color?: string
    backgroundColor?: string
}>`
    position: relative;
    width: 100%;
    height: 40px;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    border-radius: 24px;
    border: none;
    box-shadow: var(--button-box-shadow);
    color: ${({ color }) => color || 'var(--white)'};
    background: ${({ backgroundColor }) =>
        backgroundColor || 'var(--primary-color)'};
    font-size: var(--font-base);
    line-height: var(--line-height-base);

    &:not([disabled]) {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
    }

    overflow: hidden; /* Ensure pseudo-element does not overflow button */

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; /* Cover the button width */
        height: 100%;
        background: var(--secondary-color);
        transform: scaleX(0); /* Initially scale to 0 */
        transform-origin: left; /* Scale from left side */
        transition: transform 0.5s ease-in-out;
        z-index: 1; /* Ensure pseudo-element is behind text and icons */
    }

    &:hover:not([disabled])::before {
        transform: scaleX(1); /* Scale to full width on hover */
    }

    &:hover:not([disabled]) {
        background-color: var(
            --secondary-color
        ); /* Change button background color on hover */
        z-index: 0; /* Ensure pseudo-element is behind the button text and icons */
    }

    /* Ensure button content is on top of pseudo-element */
    span {
        position: relative;
        z-index: 112;
    }
`

interface IButtonProps {
    icon?: React.ReactNode
    text: string | React.ReactNode
    onClick: (e: any) => void
    color?: string
    backgroundColor?: string
    disabled?: boolean
    isPressed?: boolean
}

const Button: React.FC<IButtonProps> = ({
    icon,
    text,
    onClick,
    color,
    backgroundColor,
    disabled,
    isPressed,
}) => {
    return (
        <>
            {backgroundColor ? (
                <StyledGradientButton
                    color={color}
                    backgroundColor={backgroundColor}
                    onClick={onClick}
                    disabled={disabled}
                    style={{
                        opacity: disabled ? 0.5 : 1,
                    }}
                >
                    {icon}
                    <span>{text}</span>
                </StyledGradientButton>
            ) : (
                <StyledButton
                    color={isPressed ? 'var(--black)' : color}
                    backgroundColor={
                        isPressed ? 'var(--secondary-color)' : backgroundColor
                    }
                    onClick={onClick}
                    disabled={disabled}
                >
                    {icon}
                    {text}
                </StyledButton>
            )}
        </>
    )
}

export default Button
