import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FlexColumn, FlexRow, HorizontalSpacer } from './_common'

interface ProjectProps {
    logo: string | null
    title: string
    description: string
    linkText: string
    link: string
}

const ProjectContainer = styled.div`
    mix-blend-mode: normal;
    border-radius: 20px;
    opacity: 0.8;
    height: 100%;
    background: var(--light-gray);

    a {
        text-decoration: none;
    }

    &:hover {
        background: var(--white);
        opacity: 1;
        transition: background-color 0.3s ease-in-out;
    }

    &:hover .project-link {
        color: var(--link-color);
        transition: color 0.3s ease-in-out;
    }

    .project-container {
        padding: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .project-logo {
        width: 30px;

        img {
            width: 100%;
        }
    }

    .project-title {
        font-family: var(--secondary-font);
        color: var(--primary-color);
        font-size: 16px;
        padding-top: 15px;
        margin-bottom: 1em;
        font-weight: 700;
    }

    .project-description {
        color: var(--tertiary-color);
    }

    .project-link {
        display: flex;
        align-items: center;
        color: var(--tertiary-color);
        font-size: 12px;
        padding-top: 20px;
    }

    .project-link-icon {
        padding-left: 5px;
        font-size: 13px;
    }

    .project-link-text {
        padding-left: 20px;
        font-weight: 700;
    }
`

const Project: React.FC<ProjectProps> = ({
    logo,
    title,
    description,
    linkText,
    link,
}) => {
    return (
        <ProjectContainer className="project">
            <Link to={link} target="_blank" rel="noopener noreferrer">
                <div className="project-container">
                    <FlexColumn alignItems="flex-start">
                        <FlexRow justifyContent="flex-start">
                            {logo !== null && (
                                <div className="project-logo">
                                    <img src={logo} alt="logo" />
                                </div>
                            )}
                            <HorizontalSpacer />
                            <div className="project-title">{title}</div>
                        </FlexRow>

                        <div className="project-description">{description}</div>
                    </FlexColumn>

                    <div className="project-link">
                        <div className="project-link-icon">
                            <FontAwesomeIcon icon={faLink} />
                        </div>
                        <div className="project-link-text">{linkText}</div>
                    </div>
                </div>
            </Link>
        </ProjectContainer>
    )
}

export default Project
