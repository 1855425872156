export const SEO = {
    description:
        'Full-stack programmer specializing in TypeScript, React, and AWS. I build websites, APIs, video software, games and more.',
    keywords: [
        'Frontend Developer',
        'Backend Developer',
        'Full-stack Developer',
        'Freelance Developer',
        'Video System Design',
        'Hire React Developer',
        'Hire AWS Developer',
        'Hire Web Developer',
        'Website Development',
        'API Development',
        'Bauble Game',
    ],
}
